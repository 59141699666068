import { makeStyles } from "@material-ui/core"
import * as React from "react"
import styled from "styled-components"
import TableOfContents from "./terms&policies/TOC"
import TermsAndConditions from "./terms&policies/extendedTerms"

const Wrapper = styled.div`
  margin: auto;
  width: 95%;
  padding: 1rem;
`

const ListItem = styled.li`
  padding: 0.25rem 0;
`

const SubListItem = styled.li`
  padding: 0.25rem 0;
  width: 85%;
`

const SubListItemHeading = styled.div`
  margin: 0.5rem 0;
  font-weight: bold;
`
const useStyles = makeStyles((theme) => ({
  seperator: {
    height: '2px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    margin: '20px 0',
  },
}))


interface DisclaimerProps {}

const Disclaimer: React.FunctionComponent<DisclaimerProps> = (props) => {
  const companyName = process.env.COMPANY_NAME || "paycheck"
  const companyEmail = process.env.COMPANY_EMAIL || "info@paycheck.co.za"
  const productName = process.env.PRODUCT_NAME || "paycheck"
  const companyNumber = process.env.COMPANY_CONTACT_NUMBER || "+27 87 654 7686"
  const compRegNum = process.env.COMPANY_REG_NUM || "n/a"
  const compAdd = process.env.COMPANY_ADDRESS || "n/a"

  const classes = useStyles();
  return (
    <Wrapper>
      Terms and Conditions for {productName}
      <div className={classes.seperator}></div>
      <TableOfContents/>
      <div className={classes.seperator}></div>
      <TermsAndConditions/>
    </Wrapper>
  )
}

export default Disclaimer
