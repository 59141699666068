import React from "react"
import styled from "styled-components"
import PhoneIcon from "../images/phone-icon.svg"
import { Container, Grid, Typography, Avatar } from "@material-ui/core"
import Email from "./icons/Email"
import Call from "./icons/Call"
import { PrivacyPolicyModal } from "./dashboard/elements/modals"
const FooterContainer = styled(Container)`
  background-color: #ffffff;
  padding: 50px;
  margin-top: auto;

  @media (max-width: 960px) {
    padding: 20px;
  }
`

const GridContainer = styled(Grid)`
  @media (max-width: 960px) {
    flex-direction: column-reverse;
  }
`

const InnerGrid = styled(Grid)`
  @media (max-width: 960px) {
    justify-content: center;
  }
`

const RightsText = styled(Typography)`
  color: ##05497e;
  font-size: 10pt;

  @media (max-width: 960px) {
    border-top: 1px solid #a1a6bd;
    text-align: center;
  }
`

const ContactInfo = styled(Typography)`
  font-size: 14px;
  word-break: keep-all;
  @media (max-width: 1240px) {
    font-size: 10px;
  }
`

const ContactIcon = styled(Avatar)`
  width: 30px;
  height: 30px;
  @media (max-width: 1240px) {
    width: 20px;
    height: 20px;
  }
`

const ContactGrid = styled(Grid)`
  padding: 10px !important;
  justify-content: flex-start @media (max-width: 1240px) {
    padding: 0 10px !important;
  }
`

const ClickableLink = styled.a`
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }

  &:active {
    color: #003d80;
  }
`;

const CompactFooter: React.FC = () => {
  const companyName = process.env.COMPANY_NAME || "paycheck"
  const companyEmail = process.env.COMPANY_EMAIL || "info@paycheck.co.za"
  const companyNumber = process.env.COMPANY_CONTACT_NUMBER || "+27 87 654 7686"

  const [displayPolicy, setDisplayPolicy] = React.useState(
    false,
  )

  const showPrivacyPolicy = () => {
    setDisplayPolicy(true)
  }

  return (
    <>
    <FooterContainer maxWidth={false}>
    <GridContainer container spacing={4} justify="space-between" alignItems="center">
        {/* Rights Text */}
        <Grid item xs={12} sm={6} md={4} xl={4}>
        <RightsText>
          © {new Date().getFullYear()}{" "}
          <span style={{ textTransform: "capitalize" }}>{companyName}</span> | All rights reserved
        </RightsText>
        </Grid>

        {/* Privacy Policy */}
        <Grid item xs={12} sm={6} md={2} xl={2}>
          <ClickableLink onClick={showPrivacyPolicy}>Privacy Policy</ClickableLink>
        </Grid>

        {/* Contact Info */}
        <Grid container item xs={12} sm={12} md={6} xl={6} spacing={4} justify="space-between">
          {/* Email Section */}
          <InnerGrid
            container
            item
            xs={6}
            sm={6}
            md={6}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item xs={1} justify="flex-end">
              <Email />
            </Grid>
            <ContactGrid item xs={6}>
              <ContactInfo>{companyEmail}</ContactInfo>
            </ContactGrid>
          </InnerGrid>

          {/* Phone Section */}
          <InnerGrid
            container
            item
            xs={6}
            sm={6}
            md={6}
            alignItems="center"
            justify="flex-end"
          >
            <Grid item xs={1} justify="flex-end">
              <Call />
            </Grid>
            <ContactGrid item xs={6}>
              <ContactInfo>{companyNumber}</ContactInfo>
            </ContactGrid>
          </InnerGrid>
        </Grid>
      </GridContainer>
    </FooterContainer>
    <PrivacyPolicyModal
        displayPrivacyPolicy={displayPolicy}
        handleOnClose={() => setDisplayPolicy(false)}
      />
    </>
  )
}

export default CompactFooter
